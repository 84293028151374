/** 活动列表 */
<template>
  <div class="activity-lists content">
    <el-breadcrumb v-if="$isPc" separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/activity' }">活动专场</el-breadcrumb-item>
      <el-breadcrumb-item>活动专场列表</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="justify-between">
      <section class="details-left flex-1">
        <LoadMore :onLoadMore="onLoadMore" :loading="loading" :finished="finished">
          <List :data="pastActivity" :loading="loading" :total="pageTotal" @page="changePage">
            <template v-slot="{item}">
              <router-link :to="{path:'/activity_details',query:{id:item.id}}" class="flex">
                <el-image class="item-img flex-1 border-r4" :src="$base+item.activity_logo" fit="cover"> </el-image>
                <div class="item-info ml-20 flex-1 flex-column justify-around">
                  <h4 :class="['item-title', $isPc?'text-ellipsis-lines':'text-ellipsis']">
                    {{item.activity_name}}
                  </h4>
                  <el-row class="activity-info-list">
                    <el-col :xs="24" :sm="8" class="activity-info-item text-ellipsis" style="max-width:350px">
                      <el-image :src="require('@/assets/images/home-activity1.png')"></el-image>
                      {{item.activity_unit}}
                    </el-col>
                    <el-col :xs="24" :sm="8" class="activity-info-item text-ellipsis">
                      <el-image :src="require('@/assets/images/home-activity2.png')"></el-image>
                      {{item.activity_address}}
                    </el-col>
                    <el-col :xs="24" :sm="8" class="activity-info-item text-ellipsis">
                      <el-image :src="require('@/assets/images/home-activity3.png')"></el-image>
                      {{item.activity_date|filterTime}}
                    </el-col>
                  </el-row>
                </div>
              </router-link>
            </template>
          </List>
        </LoadMore>
      </section>
      <!-- 点击排行 -->
      <section v-if="$isPc" class="details-right flex-1 ml-20">
        <div class="details-other back-fff border-r8">
          <p class="details-other-title">点击排行</p>
          <ul class="details-other-list">
            <li v-for="(item,index) in activityOther" :key="index" class="details-other-item">
              <router-link :to="{path:'/activity_details',query:{id:item.id}}" class="details-other-item text-ellipsis">
                {{item.activity_name}}</router-link>
            </li>
          </ul>
        </div>
      </section>
    </div>
  </div>
</template>
<script>
import { getAllActivity, getCheckActivity, } from '@/api/activity'
import LoadMore from '@/components/LoadMore.vue'
import List from '@/components/List.vue'
export default {
  name: 'ActivityList',
  components: {
    LoadMore,
    List,
  },
  data () {
    return {
      pageTotal: 0,
      pastActivity: [],
      activityOther: [],//相关文章
      loading: false,//正在加载
      finished: false, // 加载完成
      page: 1
    }
  },
  mounted () {
    this.onLoadMore()
    this.getCheckActivity()
  },
  methods: {
    // 活动列表
    async getAllActivity (page) {
      try {
        let { data, total } = await getAllActivity({ page })
        this.pastActivity = this.$isPc ? data : [...this.pastActivity, ...data]
        this.pageTotal = total
        this.loading = false;
      } catch (error) { }
    },
    // 获取活动排行
    async getCheckActivity () {
      try {
        let { data } = await getCheckActivity()
        this.activityOther = data
      } catch (error) { }
    },
    // 切换分页
    changePage (page) {
      this.getAllActivity(page)
    },
    // 上拉加载
    async onLoadMore (done) {
      this.loading = true
      await this.getAllActivity(this.page)
      done && done();
      if (this.pastActivity.length >= this.pageTotal) {
        this.finished = true
        return
      }
      this.page += 1
    },
  },
}
</script>
<style lang="scss" scoped>
/deep/ .item {
  height: 150px;
  .item-info {
    width: 45%;
  }
}
.item-title {
  width: 88%;
  font-size: 20px;
}
@media (max-width: 767px) {
  /deep/ .item {
    height: 100px;
  }
}
</style>